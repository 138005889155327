var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',_vm._b({ref:"create-role",attrs:{"id":"create-roles-modal","centered":"","title":_vm.$t('common.crud.newResource', {
            resource: _vm.$tc('modules.roles.description', 1).toLowerCase(),
        }),"hideFooter":""},on:{"show":_vm.setAttributesDefaultValues}},'b-modal',_vm.$attrs,false),[_c('div',[_c('div',[_c('formulateForm',{attrs:{"name":"rolesForm"},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var isLoading = ref.isLoading;
        var hasErrors = ref.hasErrors;
return [_c('b-row',[_c('b-col',{staticClass:"mt-3 mb-2",attrs:{"cols":"12"}},[_c('formulateInput',{attrs:{"label":_vm.$t('common.crud.name'),"name":"name","placeholder":_vm.$t('common.crud.name'),"type":"text","validation":"optional|max:200,length"}})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$tc('modules.roles.permissions', 1)}},[_c('v-select',{attrs:{"id":"permissions","filterable":true,"options":_vm.permissions,"reduce":function (permission) { return permission.id; },"label":"name","placeholder":_vm.$t('common.selectOneOrMany', {
                                        resource: _vm.$tc(
                                            'modules.roles.permissions',
                                            2
                                        ),
                                    }),"required":"","multiple":"","closeOnSelect":false},model:{value:(_vm.formData.permissions),callback:function ($$v) {_vm.$set(_vm.formData, "permissions", $$v)},expression:"formData.permissions"}},[_c('template',{slot:"no-options"},[_vm._v(" No options found ! ")])],2)],1)],1)],1),_c('formulateErrors'),_c('b-row',{staticClass:"mt-3"},[(!_vm.createdRole)?_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('formulateInput',{attrs:{"disabled":hasErrors || isLoading,"label":isLoading
                                    ? _vm.$t('common.crud.creating')
                                    : _vm.$t('common.crud.create'),"inputClass":"btn btn-primary","type":"submit"}})],1):_vm._e(),(_vm.createdRole)?_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('formulateInput',{attrs:{"disabled":hasErrors || isLoading,"label":_vm.$t('common.crud.edit'),"inputClass":"btn btn-primary","type":"button"},on:{"click":function($event){return _vm.changeRoute(_vm.createdRole.id)}}})],1):_vm._e(),_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('FormulateInput',{attrs:{"label":_vm.$t('common.crud.close'),"inputClass":"btn btn-danger","type":"button"},on:{"click":function($event){return _vm.$refs['create-role'].hide()}}})],1)],1)]}}]),model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }