<template>
    <b-modal
        v-bind="$attrs"
        id="create-roles-modal"
        ref="create-role"
        centered
        :title="
            $t('common.crud.newResource', {
                resource: $tc('modules.roles.description', 1).toLowerCase(),
            })
        "
        hideFooter
        @show="setAttributesDefaultValues"
    >
        <div>
            <div>
                <formulateForm
                    v-slot="{ isLoading, hasErrors }"
                    v-model="formData"
                    name="rolesForm"
                    @submit="handleSubmit"
                >
                    <b-row>
                        <b-col cols="12" class="mt-3 mb-2">
                            <formulateInput
                                :label="$t('common.crud.name')"
                                name="name"
                                :placeholder="$t('common.crud.name')"
                                type="text"
                                validation="optional|max:200,length"
                            />
                        </b-col>

                        <b-col cols="12" class="mb-2">
                            <b-form-group
                                :label="$tc('modules.roles.permissions', 1)"
                            >
                                <v-select
                                    id="permissions"
                                    v-model="formData.permissions"
                                    :filterable="true"
                                    :options="permissions"
                                    :reduce="(permission) => permission.id"
                                    label="name"
                                    :placeholder="
                                        $t('common.selectOneOrMany', {
                                            resource: $tc(
                                                'modules.roles.permissions',
                                                2
                                            ),
                                        })
                                    "
                                    required
                                    multiple
                                    :closeOnSelect="false"
                                >
                                    <template slot="no-options">
                                        No options found !
                                    </template>
                                </v-select>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <formulateErrors />

                    <b-row class="mt-3">
                        <b-col
                            v-if="!createdRole"
                            class="d-flex justify-content-center"
                        >
                            <formulateInput
                                :disabled="hasErrors || isLoading"
                                :label="
                                    isLoading
                                        ? $t('common.crud.creating')
                                        : $t('common.crud.create')
                                "
                                inputClass="btn btn-primary"
                                type="submit"
                            />
                        </b-col>

                        <b-col
                            v-if="createdRole"
                            class="d-flex justify-content-center"
                        >
                            <formulateInput
                                :disabled="hasErrors || isLoading"
                                :label="$t('common.crud.edit')"
                                inputClass="btn btn-primary"
                                type="button"
                                @click="changeRoute(createdRole.id)"
                            />
                        </b-col>

                        <b-col class="d-flex justify-content-center">
                            <FormulateInput
                                :label="$t('common.crud.close')"
                                inputClass="btn btn-danger"
                                type="button"
                                @click="$refs['create-role'].hide()"
                            />
                        </b-col>
                    </b-row>
                </formulateForm>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { BRow, BCol, BModal, BFormGroup } from "bootstrap-vue"
import Api from "@/services/api"
import vSelect from "vue-select"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"

export default {
    components: {
        BRow,
        BModal,
        vSelect,
        BCol,
        BFormGroup,
    },
    data() {
        return {
            formData: {
                permissions: [],
                name: "",
            },
            active: false,
            permissions: [],
            relatedPermissions: [],
            permissionsResources: [],
            permissionsFunctionalities: [],
            permissionsData: [],
            createdRole: null,
        }
    },

    mounted() {
        this.getAllPermissions()
    },
    methods: {
        setAttributesDefaultValues() {
            this.formData.name = ""
            this.formData.permissions = []
        },
        changeRoute(value) {
            this.$router.push({
                name: "edit-roles",
                params: { id: value },
            })
        },
        async getAllPermissions() {
            const baseUrl = Api.apiUrl

            try {
                const response = await Api.request(
                    `${baseUrl}/roles/-actions/get-all-permissions`,
                    "GET"
                )

                this.permissions = response.data
            } finally {
                this.loading = false
            }
        },

        async handleSubmit() {
            const permissionsRequest = []
            this.formData.permissions.forEach(permission => {
                permissionsRequest.push({
                    type: "permissions",
                    id: permission,
                })
            })

            try {
                const response = await Api.create("roles", {
                    name: this.formData.name,
                    permissions: permissionsRequest,
                    include: "permissions",
                })

                this.$toast(toastMessages.getSuccessMessage())

                this.createdRole = response.data
                this.$emit("createRole", this.createdRole)
                this.$refs["create-role"].hide()
            } catch (e) {
                this.createdRole = null
                this.$toast(toastMessages.getUnexpectedError())
                this.$formulate.handle(e, "rolesForm")
            }
        },
    },
}
</script>
